import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View ,Image,TouchableOpacity,ImageBackground ,Linking ,TouchableWithoutFeedback 
} from 'react-native';
import React from 'react';
import header_logo from './assets/header_logo.svg';
import footerlogo from './assets/bottom_logo.svg';
import './app.css';
import manImage from './assets/man.svg';
import fbLogo from './assets/logos/facebookLogo.svg';
import instagramLogo from './assets/logos/instagramLogo.svg';
import twitterLogo from './assets/logos/twitterLogo.svg';
import linkedinLogo from './assets/logos/linkedinLogo.svg';
import bountyHunterLogo from './assets/BountyHunter.svg';
import appleLogo from './assets/logos/appstore_download.svg';
import googleLogo from './assets/logos/googleplay_download.svg';
import wonderGif from './assets/wondergif.gif';
import luckygif from './assets/luckygif.gif';
import { Dimensions } from 'react-native';



export default function App() {

  const [category, setCategory] = React.useState('HOME');
  //Get window dimensions
  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  const openTwitter = () => {
    Linking.openURL('https://twitter.com/NovimGames');
  }

  const openFacebook = () => {
    Linking.openURL('https://www.facebook.com/NovimGames');
  }

  const openInstagram = () => {
    Linking.openURL('https://www.instagram.com/novimgamestudios/');
  }

  const openLinkedin = () => {
    Linking.openURL('https://www.linkedin.com/company/novim-game-studios/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3Br%2Fj40muyRSGp05Wu141JMA%3D%3D');
  }

  const [onWonderHover, setOnWonderHover] = React.useState(false);
  const [onLuckyHover,setOnLuckyHover] = React.useState(false); 

  return (
    <View style={styles.container}>
      <View style = {styles.header} >
          <View style={styles.headerBody1} >
            <View style={{justifyContent: 'flex-start' ,backgroundColor: 'white',flex: 1, height: '80%'}}>
                <Image source={header_logo} style={{ flex: 1, resizeMode: 'contain' }} />
            </View>
            <View style={{justifyContent: 'flex-end', alignItems: 'flex-end' ,flex: 5, height: '80%', paddingRight: 30}}>
                <View style={{flexDirection: 'row' ,alignItems: 'center', height: '100%', justifyContent: 'flex-end'}}>
                  <TouchableOpacity 
                  onPress={() => {setCategory('HOME'); document.getElementById('HOME').scrollIntoView({ behavior: 'smooth' })}}
                  style={styles.headerButtons}>
                    <Text
                     style={category == "HOME" ? styles.textStyleSelected : styles.textStyleUnselected } > 
                     HOME
                     </Text>
                  </TouchableOpacity>
                  <TouchableOpacity 
                  onPress={() => {setCategory('GAMES'); document.getElementById('GAMES').scrollIntoView({ behavior: 'smooth' })}}
                  style={styles.headerButtons}>
                    <Text
                    style={category == "GAMES" ? styles.textStyleSelected : styles.textStyleUnselected }>
                     GAMES 
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity 
                  onPress={() => {setCategory('CONTACT_US'); document.getElementById('CONTACT').scrollIntoView({ behavior: 'smooth' });}}
                  style={styles.headerButtons}>
                    <Text
                    style={category == "CONTACT_US" ? styles.textStyleSelected : styles.textStyleUnselected }> 
                    CONTACT US 
                    </Text>
                  </TouchableOpacity>
                </View>
            </View>
          </View>
      </View>
      <View style = {styles.body}>
      <div id = 'HOME'></div>
        <View style={{width : '100%', height: 700, alignItems: 'center', justifyContent: 'center'}}>
      
          <View style = {{width : windowWidth < 700 ? '90%' : '60%', height: '100%', padding: 100, justifyContent: 'space-around'}}>
            <Image source={bountyHunterLogo} style={{flex: 1 , resizeMode: 'contain'}}>
                
            </Image>
            <Text style={{textAlign: 'center',fontFamily: 'BebasNeue', fontSize: windowWidth < 700 ? 24 : 48, fontWeight: 'bold', color: 'white'}}>
              Hammer Your Path
            </Text>
            <Text style={{textAlign:'center' , fontFamily: 'BebasNeue', fontSize: windowWidth < 700 ? 24 : 48, fontWeight: '100', color: 'white'}}>
              “Forge Ahead One Dungeon at a Time!"
            </Text>
          </View>
        </View>

        <Text style = {{fontFamily: 'BebasNeue' , fontSize: 36 , fontWeight: 'bold', color: 'white'}}> GAMES </Text>
        <div id = 'GAMES'></div>
        <View id= 'GAMES' style={{width : '100%' , height: 600 , alignItems: 'center' , justifyContent :'center', 
            flexDirection: windowWidth < 700 ? 'column' : 'row', padding: 30}}>
            <View   onMouseOver = {() => {setOnWonderHover(true);}}  
                onMouseOut = {() => {setOnWonderHover(false);}}   
                style={styles.gameComponent}>
                <ImageBackground 
                source={wonderGif} style={{flex: 1, width: '100%' }} resizeMode='center'
                  >
                  <View style = {{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                      <Text style={{ backgroundColor : 'black' , textAlign: 'center',fontFamily: 'BebasNeue', fontSize: 48, fontWeight: 'bold', color: 'white'}}>
                        Wonder
                      </Text>
                      {onWonderHover &&
                      <View style = {{width: '100%' , height: 400, alignItems: 'center'}}>
                        <TouchableOpacity style = {{flex: 1 , width: '60%' ,marginVertical: 10}}
                          onPress={() => {Linking.openURL('https://apps.apple.com/us/app/wonder-block-puzzle/id1536466249')}}
                        >
                          <Image source={appleLogo} style={{flex: 1 , resizeMode: 'contain'}} ></Image>
                        </TouchableOpacity>
                        <TouchableOpacity style = {{flex: 1 , width: '60%', marginVertical: 10 }}
                          onPress={() => {Linking.openURL('https://play.google.com/store/apps/details?id=com.NovimTechnology.pixelmaker')}}
                        >
                          <Image source={googleLogo} style={{flex: 1 , resizeMode: 'contain'}} ></Image>
                        </TouchableOpacity>
                      </View>}
                    </View>
                  </ImageBackground>
            </View>
            <View   onMouseOver = {() => {setOnLuckyHover(true);}}  
                onMouseOut = {() => {setOnLuckyHover(false);}}   
                style={styles.gameComponent}>
                <ImageBackground 
                source={luckygif} style={{flex: 1, width: '100%' }} resizeMode='center'
                  >
                  <View style = {{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                      <Text style={{ backgroundColor : 'black' , textAlign: 'center',fontFamily: 'BebasNeue', fontSize: 48, fontWeight: 'bold', color: 'white'}}>
                        Lucky Rush
                      </Text>
                      {onLuckyHover &&
                      <View style = {{width: '100%' , height: 400, alignItems: 'center'}}>
                        <TouchableOpacity style = {{flex: 1 , width: '60%' ,marginVertical: 10}}
                          onPress={() => {Linking.openURL('https://apps.apple.com/jm/app/luckyrush/id1606638799')}}
                        >
                          <Image source={appleLogo} style={{flex: 1 , resizeMode: 'contain'}} ></Image>
                        </TouchableOpacity>
                        <TouchableOpacity style = {{flex: 1 , width: '60%', marginVertical: 10 }}
                          onPress={() => {Linking.openURL('https://play.google.com/store/apps/details?id=com.NovimTechnology.LuckyRush')}}
                        >
                          <Image source={googleLogo} style={{flex: 1 , resizeMode: 'contain'}} ></Image>
                        </TouchableOpacity>
                      </View>}
                    </View>
                  </ImageBackground>
            </View>
        </View>

       
      </View>
      <View id='CONTACT' style={styles.footer} >
        <View style={{justifyContent: 'flex-start' ,flex: 5, width: '100%'}}>
          <Image source={footerlogo} style={{ flex: 1, resizeMode: 'repeat' }} />

        </View>
        <View style={{width: '90%' , flex: 2, alignItems: 'center',marginVertical: 5}}>
          <Text style={styles.textStyleUnselected}>
          © 2021 NovimGames
            </Text>
          <Text style={{textAlign: 'center',fontFamily: 'BebasNeue' , fontSize: windowWidth < 700 ? 12 : 24}}>
            All rights reserved. Unauthorized reproduction or distribution of any content on this website is strictly prohibited.
            </Text>
        </View>
        <View style={{width: 200, flexDirection: 'row', flex : 1, marginBottom: 12, justifyContent: 'space-evenly'}}>
          <TouchableOpacity onPress={openTwitter} style={{flex: 1 , resizeMode: 'contain'}}>
            <Image onPress= {openTwitter} source={twitterLogo} style={{flex: 1 , resizeMode: 'contain'}} />
          </TouchableOpacity>
          <TouchableOpacity onPress={openFacebook} style={{flex: 1 , resizeMode: 'contain'}}>
            <Image onPress ={openFacebook} source={fbLogo} style={{flex: 1 , resizeMode: 'contain'}} />
          </TouchableOpacity>
            <TouchableOpacity onPress={openInstagram} style={{flex: 1 , resizeMode: 'contain'}}>
            <Image onPress={openInstagram} source={instagramLogo} style={{flex: 1 , resizeMode: 'contain'}}/>
          </TouchableOpacity>
            <TouchableOpacity onPress={openLinkedin} style={{flex: 1 , resizeMode: 'contain'}}>
            <Image onPress = {openLinkedin} source={linkedinLogo}style={{flex: 1 , resizeMode: 'contain'}} />
          </TouchableOpacity>
        </View>
        <View>
          <div id = 'CONTACT'></div>
          <Text style={{fontFamily: 'BebasNeue' , fontSize: windowWidth < 700 ? 12 : 24}}>
             contact@novimgames.com
          </Text>
        </View>
      </View>
      
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    position: 'fixed',
    top: 0,
    width: '100%',
    padding: 10,
    backgroundColor: 'white',
    alignItems: 'center',
    height: 200,
    justifyContent: 'center',
    zIndex: 1,
  },
  headerBody1: {
    flex:1,
    width: '100%',
    backgroundColor: 'white',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  body: {
    flex:2,
    width: '100%', 
    backgroundColor: 'black',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerButtons: {
    backgroundColor: 'white', borderRadius: 10, padding: 10, marginRight: 10
  },
  textStyleSelected: {
    color: 'black',
    fontSize: 28,
    fontFamily: 'BebasNeue',
    fontWeight: 'bold',
  },
  textStyleUnselected: {
    color: 'black',
    fontFamily: 'BebasNeue',

    fontSize: 22,
  },
  footer: {
    paddingVertical : 10,
    height: 300,
    width: '100%',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gameComponent: {
    marginHorizontal: 10,
    flex : 1,
    height: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black'
  }

});


